@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-Black.woff2') format('woff2'),
    url('./Unbounded-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-Bold.woff2') format('woff2'),
    url('./Unbounded-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-ExtraLight.woff2') format('woff2'),
    url('./Unbounded-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-Medium.woff2') format('woff2'),
    url('./Unbounded-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-Light.woff2') format('woff2'),
    url('./Unbounded-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Unbounded';
    src: url('./Unbounded-Regular.woff2') format('woff2'),
    url('./Unbounded-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

